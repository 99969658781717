import React from "react";
import Logo from "../assests/images/logo.png";
import { Link } from "gatsby";

function Footer() {
  return (
    <footer className="text-gray-600 body-font sm:mx-40 bg-darkGray">
      <div className="container sm:px-5 sm:py-16 px-2 py-10 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 sm:mx-auto text-center md:text-left px-5">
          <a
            className="flex title-font font-medium items-center text-gray-900"
            href="/"
          >
            <img src={Logo} width={32} alt="LOGO" />
            <span className="font-gt ml-3 text-xl text-white tracking-wider">
              Cric<span className="text-primary">Tez</span>
            </span>
          </a>
          <p className="mt-2 text-gray-100 text-left">
            Limited edition cricket collectibles(NFTs)
          </p>
        </div>
        <div className=" sm:px-5  px-2 flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 text-left opacity-80 font-thin">
          <div className="lg:w-1/4"></div>
          <div className="lg:w-1/4"></div>
          <div className="lg:w-1/4"></div>
          <div className="lg:w-1/4 px-4">
            <nav className="list-none mb-10">
              <li>
                <Link
                  className="text-gray-100 hover:text-white"
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-100 hover:text-white"
                  to="/terms-and-conditions"
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <a
                  className="text-gray-100 hover:text-white"
                  href="mailto:support@crictez.io"
                >
                  Support
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg text-white ">
        <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
          <p className="text-gray-100 text-sm text-center sm:text-left">
            Copyright © 2021 All Rights Reserved by CricTez
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
