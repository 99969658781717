import React from "react";
import Logo from "../../assests/images/logo.png";

function Navbar() {
  return (
    <>
      <header className="text-gray-600 body-font z-50 bg-darkGray sticky top-0 z-60">
        <div className="container sm:px-40 mx-auto flex flex-wrap p-5 flex-col md:flex-row ">
          <a
            className="flex title-font font-medium items-center text-gray-900 md:mb-0"
            href="/"
          >
            <img src={Logo} alt="LOGO" width={32} />
            <span className="font-gt ml-3 text-xl text-white tracking-wider">
              Cric<span className="text-primary">Tez</span>
            </span>
          </a>
          <nav className="md:ml-auto flex flex-wrap items-center text-base justify-center"></nav>
          {/* <button
            className="font-gt bg-primary items-center border-0 py-1 px-3 focus:outline-none hover:bg-primaryDark text-white mt-4 md:mt-0 hidden sm:inline-flex"
            onClick={() => (window.location.href = appURL)}
          >
            Get Started
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-4 h-4 ml-1"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button> */}
        </div>
      </header>
    </>
  );
}

export default Navbar;
